<template>
    <data-list
            ref="dataList"
            :add-btn="null"
            url="/activity-enroll"
            :query="`&sort=-id&expand=activity&filter[activity_id]=${acivity.id}`"
            name="活动报名"
            hide-action
            :height="500"
            :columns="columns"
            :search-key-type="{name: true}"
            v-if="acivity">
        <template #title>
            <span>报名列表</span>
        </template>
        <template v-slot:query="{form}">
            <a-form-model-item label="">
                <a-input v-model="form.name" placeholder="输入姓名查询"/>
            </a-form-model-item>
        </template>
    </data-list>
</template>

<script>
    export default {
        name: "EnrollList",
        props: {
            acivity: Object
        },
        data() {
            return {
                columns: [
                    {
                        title: '活动名称',
                        dataIndex: 'activity',
                        customRender: text => {
                            return <span>{text ? text.name : '--'}</span>
                        }
                    },
                    {title: '姓名', dataIndex: 'name'},
                    {title: '联系方式', dataIndex: 'mobile'},
                    {
                        title: '单位及职务',
                        dataIndex: 'detail',
                        customRender: text => {
                            return <span>{text || '--'}</span>
                        }
                    },
                    {
                        title: '参与人数',
                        dataIndex: 'number',
                        customRender: text => {
                            return <span>{text || '--'}</span>
                        }
                    },
                ],
            }
        },
    }
</script>

<style scoped lang="less">
</style>
