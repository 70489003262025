<template>
    <div class="activity-detail" v-if="detail">
        <div class="flex-box align-center justify-between">
            <div class="title-txt">{{detail.name}}</div>
            <a-button type="primary" @click="editDetail" v-show="tab == 1">编辑</a-button>
        </div>
        <a-tabs default-active-key="1" v-if="detail" @change="key => tab = key">
            <a-tab-pane key="1" tab="基础信息">
                <div class="detail-info">
                    <div class="detail-info-item">
                        <div class="detail-info-label">活动时间</div>
                        <div class="detail-info-value">{{detail.start_time}}</div>
                    </div>
                    <div class="detail-info-item">
                        <div class="detail-info-label">活动地点</div>
                        <div class="detail-info-value">{{detail.location}}</div>
                    </div>
                    <div class="detail-info-item">
                        <div class="detail-info-label">活动人数</div>
                        <div class="detail-info-value">{{detail.enrollNum}}人</div>
                    </div>
                    <div class="detail-info-item">
                        <div class="detail-info-label">线下人数</div>
                        <div class="detail-info-value">{{Number(detail.add_people_num)}}人</div>
                    </div>
                    <div class="detail-info-item">
                        <div class="detail-info-label">活动封面</div>
                        <div class="detail-info-value">
                            <div class="file-list" v-if="detail.img">
                                <img class="file-item" :src="f" v-for="(f, i) in detail.img.split(',')" :key="i" @click="previewImg(f)">
                            </div>
                            <div v-else>无</div>
                        </div>
                    </div>
                    <div class="detail-info-item">
                        <div class="detail-info-label">活动介绍</div>
                        <div class="detail-info-value">
                            <article v-if="detail.info">
                                <p class="detail-info-p" v-for="(p, i) in detail.info.split(/\r|\n/)" :key="i">{{p}}</p>
                            </article>
                            <span v-else>无</span>
                        </div>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="报名列表">
                <enroll-list :acivity="detail"></enroll-list>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
    import EnrollList from "./EnrollList";
    export default {
        name: "ActivityDetail",
        components: {
            EnrollList
        },
        props: {
            detail: Object
        },
        data() {
            return {
                tab: "1",
            }
        },
        methods: {
            editDetail() {
                if(this.tab == 1) {
                    this.$emit("edit")
                }
            },
            previewImg(f) {
                window.open(f, "_blank")
            }
        }
    }
</script>

<style scoped lang="less">
    .title-txt {
        font-weight: bold;
        font-size: 16px;
        color: @heading-color;
        line-height: 32px;
    }
    .album-teacher {
        padding: @padding-xs;
        cursor: pointer;
        &:hover {
            background-color: @background-color-base;
        }
    }
</style>
